$primary-color-blue: #c9dff2;
$azul-claro-sophia: #b6d0f2;
$azul-sophia: #3678ba;

$amarillo-sophia: #f2cc0f;
$naranjo-sophia: #d96704;
$rojo-sophia: #c80326;
$verde-lima-sophia: #d5da66;
$lila-sophia: #e0d0f4;
$negro-sophia: #0c2447;
$blanco-sophia: #fff;

$rojo-error-sophia: #f00444;
$verde-exito-sophia: #008218;

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap");

.file-viewer-dialog .mat-mdc-dialog-container .mdc-dialog__surface {
  overflow: visible;
  position: relative;
}

html,
body {
  margin: 0 !important;
  padding: 0;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit; // Aplica box-sizing a todos los elementos
}

:host {
  font-family: "Open Sans", sans-serif;
}

p {
  color: $negro-sophia;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

h2 {
  color: $negro-sophia;
  font-family: "Open Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.mat-mdc-outlined-button:not(:disabled) {
  border-color: $naranjo-sophia !important;
  border-radius: 28px;
  width: 290px;
  height: 48px;
  color: $negro-sophia !important;
  text-align: center;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs
  > .mat-mdc-tab-header
  .mat-mdc-tab {
  flex-grow: 1;
  border-radius: 12px 12px 0px 0px;
  background: var(--Gris-claro-Sophia, #f5f7fa);
  box-shadow: 4px 4px 6px 0px rgba(12, 36, 71, 0.1);
}

.mdc-tab
  mat-mdc-tab
  mat-mdc-focus-indicator
  mdc-tab--active
  mdc-tab-indicator--active {
  border-radius: 12px 12px 0px 0px;
  background-color: $negro-sophia !important;
  box-shadow: 4px 4px 8px 0px rgba(12, 36, 71, 0.1);
}
.mat-mdc-tab.mdc-tab--active:hover .mdc-tab-indicator__content--underline {
  border: none !important;
}

.mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
  color: $negro-sophia !important;
}

.mat-mdc-tab .mdc-tab__text-label {
  color: $negro-sophia !important;
  font-family: "Open Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

/*ESTILOS FORMS FICHA CLINICA*/

.contenedor-principal-formulario {
  width: auto;
  display: flex;
  padding: 48px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 48px;
  border-radius: 0px 0px 12px 12px;
  background: $blanco-sophia;
  box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 100px;
}

.layaout-formulario {
  width: auto;
  height: auto;
  left: 71px;
  padding-bottom: 100px;
  bottom: 122.372px;
  border-radius: 15px;
}

.formulario {
  display: flex;
  width: auto;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 20px;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

/*ESTILOS BUTTONS*/
.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #d96704 !important;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
  display: flex;
  width: 290px;
  min-width: 185px;
  min-height: 48px;
  max-height: 48px;
  padding: 14px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 28px;
  margin-bottom: 15px;
}

.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
  background-color: #f2cc0f !important;
  border: 1px solid #3678ba;
}
.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled)
  .mdc-evolution-chip__icon--trailing {
  color: #0c2447 !important;
}

:root {
  --fc-small-font-size: 0.85em;
  --fc-page-bg-color: #fff;
  --fc-neutral-text-color: grey;
  --fc-button-text-color: #fff;
  --fc-button-bg-color: #2c3e50;
  --fc-button-border-color: #2c3e50;
  --fc-button-hover-bg-color: #1e2b37;
  --fc-button-hover-border-color: #1a252f;
  --fc-button-active-bg-color: #1a252f;
  --fc-button-active-border-color: #151e27;
  --fc-event-bg-color: #3788d8;
  --fc-event-border-color: #3788d8;
  --fc-event-text-color: #0c2447;
  --fc-event-selected-overlay-color: rgba(0, 0, 0, 0.25);
  --fc-more-link-bg-color: #d0d0d0;
  --fc-more-link-text-color: inherit;
  --fc-event-resizer-thickness: 8px;
  --fc-event-resizer-dot-total-width: 8px;
  --fc-event-resizer-dot-border-width: 1px;
  --fc-non-business-color: hsla(0, 0%, 84%, 0.3);
  --fc-today-bg-color: rgba(188, 232, 241, 0.3);
  --fc-now-indicator-color: red;
}

.fc-timegrid-event,
.fc-timegrid-more-link {
  font-weight: 600;
  border-radius: 3px;
  font-size: var(--fc-small-font-size);
  font-family: "Open Sans";
  font-size: 12px;
}

.fc-day-today {
  background-color: #d7e5f2;
  color: white;
  border-top-left-radius: 8px;
  text-align: justify;
  font-family: "Open Sans";
  font-weight: 700;
  font-size: 16.27px;
  width: 187px;
  height: 45.433px;
  padding: 12px;
}

.fc table {
  border-collapse: collapse;
  font-family: "Open Sans";
  font-weight: 600;
  border-spacing: 0px;
  font-size: 16.27px;
  color: $negro-sophia;
  width: 187px;
  height: 45.433px;
  padding: 12px;
}

.fc-daygrid-day-number {
  text-transform: uppercase;
}

/* Para la vista de semana */
.fc-col-header-cell {
  text-transform: uppercase;
}

.fc .fc-scrollgrid {
  border-collapse: separate;
  border-radius: 13px;
}

.fc .fc-scrollgrid,
.fc .fc-scrollgrid table {
  table-layout: fixed;
  width: 100%;
}

.fc-theme-standard .fc-scrollgrid {
  border: none;
}

.fc table {
  border-collapse: collapse;
  border-spacing: 0px;
  font-size: 1em;
}

.fc,
.fc *,
.fc ::after,
.fc ::before {
  box-sizing: border-box;
}

table {
  display: table;
  border-collapse: separate;
  box-sizing: border-box;
  text-indent: initial;
  unicode-bidi: isolate;
  border-spacing: 2px;
  border-color: white;
}

.fc-theme-standard td,
.fc-theme-standard th {
  border: none;
}

.fc .fc-cell-shaded,
.fc .fc-day-disabled {
  background: var(--fc-neutral-bg-color);
}

.fc .fc-col-header-cell-cushion {
  padding: 12px;
}

.fc .fc-button .fc-icon {
  font-size: 1.5em;
  vertical-align: middle;
  color: $negro-sophia;
}

.fc .fc-button-primary {
  background-color: #fafafa;
  border-color: white;
  color: $negro-sophia;
}

.fc .fc-toolbar-title {
  margin: 0px;
  color: $negro-sophia;
  font-family: "Open Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.fc .fc-timegrid-axis-cushion {
  display: none;
  height: 0;
}

.mat-mdc-dialog-container {
  width: 600px;
}

.mat-mdc-dialog-container .mat-mdc-dialog-title + .mat-mdc-dialog-content {
  padding-top: 0;
  text-align: -webkit-center;
}

.mat-mdc-dialog-container .mdc-dialog__title {
  color: $negro-sophia;
  text-align: center;
  font-family: "Open Sans", sans-serif;
}

.fc .fc-button-primary {
  background-color: white;
  border-color: white;
  color: #0c2447;
}

.fc .fc-button-primary:hover {
  background-color: white;
  border-color: white;
  color: white;
}

.fc .fc-button:focus {
  box-shadow: white;
}

.fc .fc-button-primary:not(:disabled):active {
  background-color: white;
  border-color: white;
}

.mat-mdc-dialog-actions {
  place-self: center;
}
.fc .fc-button-primary {
  background-color: #f5f7fa;
  border-color: white;
  color: #0c2447;
  font-family: "Open Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.fc .fc-button-primary:not(:disabled).fc-button-active,
.fc .fc-button-primary:not(:disabled):active {
  border-radius: 0;
  background: #b6d0f2;
  color: white;
  border: none;
}

.fc .fc-button-primary:hover {
  background-color: #b6d0f2;
  color: white;
  border-color: #b6d0f2;
}

.fc .fc-button-primary:not(:disabled).fc-button-active:focus,
.fc .fc-button-primary:not(:disabled):active:focus {
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0.2rem;
}

.fc-timegrid-event-harness-inset .fc-timegrid-event,
.fc-timegrid-event.fc-event-mirror,
.fc-timegrid-more-link {
  box-shadow: 0 0 0 0px var(--fc-page-bg-color);
}
.fc-direction-ltr .fc-timegrid-col-events {
  margin: 0px 0px 0px 3px;
}

.mat-mdc-unelevated-button {
  padding: 14px 20px;
  border-radius: 28px;
}

:host ::ng-deep .mat-mdc-unelevated-button:not(:disabled) {
  padding: 14px 20px;
  border-radius: 28px;
  color: $blanco-sophia;
  background: $naranjo-sophia;
}

.mdc-button
  .mdc-button--unelevated
  .mat-mdc-unelevated-button
  .mat-unthemed
  .mat-mdc-button-base {
  background-color: $naranjo-sophia;
}

.mat-mdc-option .mdc-list-item__primary-text {
  font-family: "Open Sans" !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 550 !important;
  line-height: 22px !important;
  color: $negro-sophia !important;
}

::ng-deep .mat-mdc-select {
  color: $negro-sophia !important;
}

.mdc-outlined-text-field-input-text-color {
  color: $negro-sophia !important;
}

.mat-mdc-select-value-text {
  color: #0c2447 !important;
}

.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled)
  .mdc-list-item__primary-text {
  color: $negro-sophia !important;
  font-family: "Open Sans" !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 600 !important;
}
.mat-mdc-option .mat-pseudo-checkbox-minimal {
  margin-left: 16px;
  flex-shrink: 0;
  display: none !important;
}

.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field
  .mdc-notched-outline__notch {
  border-left: none !important;
}

.mat-mdc-progress-spinner .mdc-circular-progress__indeterminate-circle-graphic {
  stroke: white !important;
}

.custom-speech-dialog .mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: 18px;
}

.custom-confirmation-dialog .mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: 12px;
}
.mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius:  12px !important;
}


.mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 10px !important;
}

